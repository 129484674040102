<template>
  <div class="main-content py-2 overflow-auto position-relative">
    <div class="row m-0">
      <div
        class="camera-list col-12 col-sm-6 col-md-4 col-lg-3 p-4 pb-0"
        v-for="cctv in cctvs"
        :key="`CCTV${cctv.id}`"
      >
        <div
          class="embed-responsive embed-responsive-16by9 home-image-container w-100 rounded-xl overflow-hidden shadow clickable"
          @click="viewCctv(cctv)"
        >
          <img
            class="w-100 embed-responsive-item"
            :src="`${cctvSrc(cctv)}?${cacheKey}`"
            :class="{ invisible: cctv.isError }"
            alt=""
            @error="cctvIsOffline(cctv)"
          />
        </div>
        <div class="w-100 d-flex justify-content-between">
          <div
            class="py-2 pr-3 d-flex align-items-center justify-content-start cam-title-container"
          >
            <img class="mr-2" src="@/assets/ic_video.png" alt="" />
            <p class="cam-title mb-0">{{ cctv.name.split("_").join(" ") }}</p>
          </div>
          <div
            class="py-2 d-flex align-items-center justify-content-start cam-title-container"
          >
            <img class="mr-2" src="@/assets/ic_location.png" alt="" />
            <p class="cam-title mb-0">
              <span class="font-weight-bold" v-if="cctv.type == 'aisc'">{{
                cctv.current_location || "Location not set"
              }}</span>
              <span v-else>PRSB</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <loading-spinner v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import API from "@/api";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "Camera-CCTV",
  components: {
    "loading-spinner": LoadingSpinner,
  },
  data() {
    return {
      cctvs: [],
      isLoading: false,
      cacheKey: moment().unix(),
      cctvInterval: null,
      cacheInterval: null,
    };
  },
  watch: {
    "$route.name": {
      handler() {
        this.getCctvs();

        this.cctvInterval = setInterval(() => {
          this.getCctvs(true);
        }, 180000);

        this.cacheInterval = setInterval(() => {
          this.cacheKey = moment().unix();
        }, 10 * 1000);
      },
      immediate: true,
    },
  },
  computed: {
    apiHost() {
      return (serverNum) => {
        if (serverNum && serverNum == 2) {
          return API.apiHostServer2();
        } else {
          return API.apiHostServer1();
        }
      };
    },
    cctvSrc() {
      return (cctv) => {
        if (cctv.name.toLowerCase().includes("aisc_uauc")) {
          const uaucNameArray = cctv.name.split("_");

          return uaucNameArray.length == 2
            ? "https://uauc.inference.asia"
            : `https://uauc${
                uaucNameArray[uaucNameArray.length - 1]
              }.inference.asia`;
        } else {
          return `${this.apiHost(cctv.serverNum)}:${cctv.broadcastPort}`;
        }
      };
    },
  },
  methods: {
    cctvIsOffline(cctv) {
      let cc = this.cctvs.find((c) => c.id == cctv.id);

      let cIndex = this.cctvs.findIndex((c) => c.id == cc.id);

      cc.isError = true;

      this.$set(this.cctvs, cIndex, cc);
    },
    getCctvs(isSilent) {
      if (!isSilent) {
        this.isLoading = true;
      }

      API.get(
        `cameras?filters[type][$eq]=${
          this.$route.name == "Cameras"
            ? "cctv"
            : this.$route.name.toLowerCase()
        }`
      )
        .then((retVal) => {
          this.cctvs = retVal.data;

          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);

          this.isLoading = false;
        });
    },
    viewCctv(cctv) {
      if (cctv) {
        this.$router.push({
          name: "Detections",
          params: {
            cameraId: cctv.id,
          },
        });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.cctvInterval);

    clearInterval(this.cacheInterval);
  },
};
</script>
